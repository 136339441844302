var data = [
  { 'id': 1, 'label': 'ampul' },
  { 'id': 2, 'label': 'auc' },
  { 'id': 3, 'label': 'cc' },
  { 'id': 4, 'label': 'damla' },
  { 'id': 5, 'label': 'gy' },
  { 'id': 6, 'label': 'gr' },
  { 'id': 7, 'label': 'mcg' },
  { 'id': 8, 'label': 'mcg/m²' },
  { 'id': 9, 'label': 'mcg/kg' },
  { 'id': 10, 'label': 'mg' },
  { 'id': 11, 'label': 'mg/kg' },
  { 'id': 12, 'label': 'mg/m²' },
  { 'id': 13, 'label': 'unit/m²' },
  { 'id': 14, 'label': 'ml' },
  { 'id': 15, 'label': 'mu' },
  { 'id': 16, 'label': 'mu/m²' },
  { 'id': 17, 'label': 'mu/kg' },
  { 'id': 18, 'label': 'ölçek' },
  { 'id': 19, 'label': 'tablet' },
  { 'id': 20, 'label': 'unite' }
]

export { data as options };
