var data = [
    { 'label': 'adrenal karsinoma adjuvan', 'id': 1 },
    { 'label': 'adrenal karsinom metastatik veya ileri evre', 'id': 2 },
    { 'label': 'adrenal karsinom neoadjuvan', 'id': 3 },
    { 'label': 'akut miyeloid lösemi', 'id': 4 },
    { 'label': 'anal adjuvan', 'id': 5 },
    { 'label': 'anal definitif kemoradyoterapi', 'id': 6 },
    { 'label': 'anal metastatik veya ileri evre', 'id': 7 },
    { 'label': 'anal neoadjuvan', 'id': 8 },
    { 'label': 'baş ve boyun definitif kemoradyoterapi', 'id': 9 },
    { 'label': 'baş ve boyun adjuvan', 'id': 10 },
    { 'label': 'baş ve boyun metastatik veya ileri evre', 'id': 11 },
    { 'label': 'beyin adjuvan', 'id': 12 },
    { 'label': 'beyin metastatik veya ileri evre', 'id': 13 },
];

export { data as options };