var data = [{
    'label': 'Henüz işlem yapılmadı',
    'id': 1
  },
  {
    'label': 'Tedavi burada uygulanacak (ilaç istemi yapılarak)',
    'id': 2
  },
]

export { data as options };
