var data = [{
  'id': 1,
  'firstname': 'ilk ad',
  'lastname': 'soy ad',
  'date_of_birth': '1995-01-01',
  'sex': 1,
  'color': 0,
}, {
  'id': 2,
  'firstname': 'ilk ad 2',
  'lastname': 'soy ad 2',
  'date_of_birth': '1980-01-01',
  'sex': 0,
  'color': 0,
}]

export { data as options };
