var data = [{
  'adjusted_weight': 72,
  'bmi': 26,
  'bsa': 145,
  'bsa_date': '2022-01-01',
  'height_cm': 160,
  'height_inch': 1.5,
  'id': 1,
  'ideal_weight': 70,
  'PatientId': 23,
  'weight_kg': 75,
  'weight_lbs': 50,
}]

export { data as options };
