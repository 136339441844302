var data = [{
  'id': 1,
  'DrugApplicationStatusId': 1,
  'EligibleId': 1,
}, {
  'id': 1,
  'DrugApplicationStatusId': 1,
  'EligibleId': 2,
}, {
  'id': 1,
  'DrugApplicationStatusId': 1,
  'EligibleId': 3,
}];

export { data as options };
