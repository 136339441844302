var data = [{
  'id': 1,
  'PatientId': 1,
  'PatientDiagnosisId': 1,
  'TreatmentTypeId': 1,
  'CreatedBy': 1,
  'TreatmentStatusId': 1,
  'treatment_name': 'cf => sisplatin (75 mg/m2 , 1.gün)  , fluorourasil (5000 mg/m2 , infüzör pompası ile , 1.gün)  [21 gündebir , 6kür]',
  'step_no': 1,
  'start_date': '2022-01-01T09:00:00.000000',
  'end_date': '2022-01-01T09:00:00.000000',
}]

export { data as options };
