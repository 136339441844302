var sql_database = {
  'table_order': [
    'ApplicationType',
    'DateChangeReason',
    'Diagnosis',
    'Drug',
    'DrugApplicationStatus',
    'DrugBrands',
    'DrugUnit',
    'LaboratoryTest',
    'LaboratoryUnit',
    'OperationControl',
    'Patient',
    'PatientBsa',
    'PatientDiagnosis',
    'Permission',
    'TreatmentStatus',
    'TreatmentType',
    'User',
    'UserFavoriteDrugs',
    'UserPermission',
  ],
  'tables': {
    'OperationControl': {
      'info': 'Geliştirme süreçlerindeki iş paketlerinin tamamlama süreçlerine ait listeyi ve tamamlama durumlarının takip edilmesini sağlar',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'İş paketi adını içerir.' },
        { 'name': 'status', 'type': 'int', 'info': '0 ve 1 değerleri kaydedillir. 1 işlemin tamamlandığını gösterir.' },
        { 'name': 'subject', 'type': 'text', 'info': 'Hangi konuda bir iş paketi olduğunu gruplandırma amacıyla gösterir.' },
        { 'name': 'button_name', 'type': 'text', 'info': 'İşlem bir buton adı gerektiriyorsa buton adını içeririr.' },
        { 'name': 'trigger_point', 'type': 'text', 'info': 'İşlemin tetikleme zamanı hakkında sayfa ya da diğer bilgileri içerir.' },
        { 'name': 'method_name', 'type': 'text', 'info': 'İşlem bir fonksiyon adı (metod) gerektiriyorsa adını içerir.' },
        { 'name': 'info', 'type': 'text', 'info': 'İşlem hakkında detaylı bilgiyi içerir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'TreatmentType': {
      'info': 'Tedavi uygulama tipi seçilir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Tedavi tipi adını içerir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'ApplicationType': {
      'info': 'İlaç uygulama tipi seçilir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'İlaç uygulama tipi adını içerir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'Diagnosis': {
      'info': 'ICD10 tanı kodlarını içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'ICD10 tanı adını içermektedir.' },
        { 'name': 'ICD10', 'type': 'text', 'info': 'ICD10 tanı kodunu içermektedir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'PatientDiagnosis': {
      'info': 'Hastaya kayıtlı tanıları içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'PatientId', 'type': 'int', 'info': 'Hasta tablosundaki hastaya ait id değerini gösterir', 'relation_table': 'Patient', 'relation_table_variable': 'id' },
        { 'name': 'DiagnosisId', 'type': 'int', 'info': '', 'relation_table': 'Diagnosis', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'PatientBsa': {
      'info': 'Hastaya kayıtlı kilo, boy, vücut yüzey alanı gibi bilgileri içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'PatientId', 'type': 'int', 'info': 'Hasta tablosundaki hastaya ait id değerini gösterir', 'relation_table': 'Patient', 'relation_table_variable': 'id' },
        { 'name': 'bsa_date', 'type': 'datetime', 'info': 'Eklenen bilgilere ait tarih bilgisini içerir.' },
        { 'name': 'adjusted_weight', 'type': 'float', 'info': 'Düzeltilmiş kilo değerini içeririr.' },
        { 'name': 'bmi', 'type': 'float', 'info': 'Girilen değerlerle hesaplanan vücut kitle indeksi değerini içeririr' },
        { 'name': 'bsa', 'type': 'float', 'info': 'Vücut yüzey alanı bilgisini içerir. (Body Surface Area baş harflerinin kısaltmasıdır)' },
        { 'name': 'height_cm', 'type': 'float', 'info': 'Boy cm birimiyle' },
        { 'name': 'height_inch', 'type': 'float', 'info': 'Boy inch birimiyle' },
        { 'name': 'ideal_weight', 'type': 'float', 'info': 'İdeal kilo bilgisini içeririr' },
        { 'name': 'weight_kg', 'type': 'float', 'info': 'Kilo kg birimiyle' },
        { 'name': 'weight_lbs', 'type': 'float', 'info': 'Kilo lbs birimiyle' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'LaboratoryTest': {
      'info': 'Kayıtlı laboratuar test bilgilerini içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Laboratuar test adını içerir' },
        { 'name': 'lower_bound', 'type': 'float', 'info': 'Laboratuar test alt sınırını içerir' },
        { 'name': 'upper_bound', 'type': 'float', 'info': 'Laboratuar test üst sınırını içerir' },
        { 'name': 'LaboratoryUnitId', 'type': 'int', 'info': 'LaboratoryUnitId tablosundaki id bilgisini içerir.', 'relation_table': 'LaboratoryUnit', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'LaboratoryUnit': {
      'info': 'Laboratuar testleri için birim bilgilerini içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Birim adını içerir' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'DrugBrands': {
      'info': 'İlaçlara ait ürünleri içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Ürün adı' },
        { 'name': 'DrugId', 'type': 'int', 'info': '', 'relation_table': 'Drug', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'DrugUnit': {
      'info': 'İlaç birimlerini içermektedir.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'İlaç birim adını içermektedir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'DateChangeReason': {
      'info': 'Tarih değiştirme nedenlerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Tarih değiştirme nedenini içerir' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'TreatmentSchemaSystem': {
      'info': 'Sistem genel şemalarını içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': 'Tablo otomatik artan id değerini oluşturmaktadır.' },
        { 'name': 'label', 'type': 'text', 'info': 'Tedavi şemasına ait adı içermektedir.' },
        { 'name': 'reference', 'type': 'text', 'info': 'XXX bilimsel çalışmasından alınmıştır şeklinde bilgileri içerir.' },
        { 'name': 'note', 'type': 'text', 'info': 'Tedavi şeması hakkında genel bilgileri içerir. Bu tedavinin hastaya kaydedilirken hasta tedavileri tablolarına bu tedaviyle ilgili bu not da kaydedilir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'DrugApplicationStatus': {
      'info': 'Tedavilerdeki ilaçlara ait durum bilgisine ait id değerlerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'label', 'type': 'text', 'info': 'Durum bilgisi değerlerini içeririr.' },
        { 'name': 'background_color', 'type': 'text', 'info': 'Uygulama tipine bağlı arka plan renk bilgisini içeririr.' },
        { 'name': 'PermissionId', 'type': 'int', 'info': 'Hangi izin idsine sahip olan kullanıcıların bu işlemi gerçekleştirebileceği bilgisini içerir.', 'relation_table': 'Permission', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'TreatmentStatus': {
      'info': 'Tedavilere ait durum bilgisine ait id değerlerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'label', 'type': 'text', 'info': 'Tedaviye ait durum bilgisi değerlerini içeririr.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'UserFavoriteDrugs': {
      'info': 'Kullanıcılara ait favori ilaçlar listesini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'DrugId', 'type': 'text', 'info': 'İlaç tablosu id bilgisini içerir', 'relation_table': 'Drug', 'relation_table_variable': 'id' },
        { 'name': 'UserId', 'type': 'text', 'info': 'Kullanıcı id bilgisini içerir.', 'relation_table': 'User', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'User': {
      'info': 'Kullanıcıları içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'firstname', 'type': 'text', 'info': 'ilk ad bilgisini içeririr.' },
        { 'name': 'lastname', 'type': 'text', 'info': 'son ad bilgisini içeririr.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'Permission': {
      'info': 'Sistem izinlerine ait id değerlerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'label', 'type': 'text', 'info': 'İzin bilgisi değerini içeririr.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'UserPermission': {
      'info': 'Kullanıcı izinlerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'UserId', 'type': 'int', 'info': 'User tablosundaki kullanıcı id değeri buraya yazılır.', 'relation_table': 'User', 'relation_table_variable': 'id' },
        { 'name': 'PermissionId', 'type': 'text', 'info': 'İzin tablosu id bilgisini içerir', 'relation_table': 'Permission', 'relation_table_variable': 'id' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'Patient': {
      'info': 'Hasta kimlik bilgilerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'firstname', 'type': 'text', 'info': 'ilk ad bilgisini içeririr.' },
        { 'name': 'lastname', 'type': 'text', 'info': 'son ad bilgisini içeririr.' },
        { 'name': 'date_of_birth', 'type': 'date', 'info': 'doğum tarihi bilgisini içerir.' },
        { 'name': 'sex', 'type': 'int', 'info': 'cinsiyet bilgisini içerir.' },
        { 'name': 'color', 'type': 'int', 'info': 'ırk rengi bilgisini içerir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
    'Drug': {
      'info': 'İlaç içeriklerini içeren tablodur.',
      'variable': [
        { 'name': 'id', 'type': 'int NOT NULL IDENTITY(1,1) PRIMARY KEY', 'info': '' },
        { 'name': 'label', 'type': 'text', 'info': 'ilaç içerik adını içerir.' },
      ],
      'example_rows': [],
      'sql_list': []
    },
  }
};

export { sql_database as options };
