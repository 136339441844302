var data = [
  { 'id': 1, 'label': 'mg/dl' },
  { 'id': 2, 'label': 'mg/ml' },
  { 'id': 3, 'label': 'mg' },
  { 'id': 4, 'label': 'mü' },
  { 'id': 5, 'label': 'ünite' },
  { 'id': 6, 'label': 'ml' },
  { 'id': 7, 'label': 'gr' },
  { 'id': 8, 'label': 'mcg' },
  { 'id': 9, 'label': 'mcg/ml' },
  { 'id': 10, 'label': 'mcg/dl' },
]

export { data as options };
