var data = [{
    'label': 'Henüz işlem yapılmadı',
    'id': 1,
    'UserPermissionId': 1,
    'background_color': '#fae482e0'
  },
  {
    'label': 'Tedavi burada uygulanacak (ilaç istemi yapılarak)',
    'id': 2,
    'UserPermissionId': 2,
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi burada uygulanacak (ilaç istemi yapılmadan)',
    'id': 3,
    'UserPermissionId': 3,
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi burada uygulanacak (İlaç istemi yapılmadan. (Klinik araştırma hastası))',
    'id': 4,
    'UserPermissionId': 4,
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi başka bir merkezde uygulanmış',
    'id': 5,
    'UserPermissionId': 5,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Hasta ilacı evde aldığını ifade etti',
    'id': 6,
    'UserPermissionId': 6,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Tedavi onayı geri alındı',
    'id': 7,
    'UserPermissionId': 7,
    'background_color': '#fae482e0'
  },
  {
    'label': 'Bu ilaç bugünde uygulanmayacak. İptal edildi',
    'id': 8,
    'UserPermissionId': 8,
    'background_color': '#f86c6b'
  },
  {
    'label': 'Hasta bu tedaviyi daha önceden aldı',
    'id': 9,
    'UserPermissionId': 9,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Hasta bu tedaviyi kendisi uygulayacak',
    'id': 10,
    'UserPermissionId': 10,
    'background_color': '#fae482e0'
  },
  {
    'label': 'Radyoterapi uygulandı',
    'id': 11,
    'UserPermissionId': 11,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerildiği gibi uygulanmıştır. Aynı şekilde devam edilecek',
    'id': 12,
    'UserPermissionId': 12,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerildiği gibi uygulanmıştır',
    'id': 13,
    'UserPermissionId': 13,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerilenden farklı uygulanmıştır. Aynı şekilde devam edilecek',
    'id': 14,
    'UserPermissionId': 14,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerilenden farklı uygulanmıştır',
    'id': 15,
    'UserPermissionId': 15,
    'background_color': '#4dbd74'
  },
  {
    'label': 'Tedavi uygulanmamıştır',
    'id': 16,
    'UserPermissionId': 16,
    'background_color': '#f86c6b'
  },
  {
    'label': 'Ürün seçimi yapıldı',
    'id': 17,
    'UserPermissionId': 17,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'Ürün seçimi iptal edildi',
    'id': 18,
    'UserPermissionId': 18,
    'background_color': '#47D4DC'
  },
  {
    'label': 'Ürün seçimleri düzenlendi',
    'id': 19,
    'UserPermissionId': 19,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verildi',
    'id': 20,
    'UserPermissionId': 20,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi',
    'id': 21,
    'UserPermissionId': 21,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Stokta yok)',
    'id': 22,
    'UserPermissionId': 22,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Hatalı doz?)',
    'id': 23,
    'UserPermissionId': 23,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Sistemde ilacın kaydı yok)',
    'id': 24,
    'UserPermissionId': 24,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç hazırlandı. (Manuel)',
    'id': 25,
    'UserPermissionId': 25,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hazırlandı. (Tam otomatik sistemde)',
    'id': 26,
    'UserPermissionId': 26,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç kullanıma hazır bir ürün',
    'id': 27,
    'UserPermissionId': 27,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hazırlanamadı',
    'id': 28,
    'UserPermissionId': 28,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç hazırlanamadı. (Doz hatası?)',
    'id': 29,
    'UserPermissionId': 29,
    'background_color': '#9e9e9e'
  },
  {
    'label': 'Hasta ilaç karşılaştırması başarıyla tamamlandı',
    'id': 30,
    'UserPermissionId': 30,
    'background_color': '#47D4DC'
  },
  {
    'label': 'Bu ilaç hastaya ait değil',
    'id': 31,
    'UserPermissionId': 31,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlacın tamamı uygulandı',
    'id': 32,
    'UserPermissionId': 32,
    'background_color': '#4dbd74'
  },
  {
    'label': 'İlaç uygulandı. Fakat tamamı uygulanamadı',
    'id': 33,
    'UserPermissionId': 33,
    'background_color': '#4dbd74'
  },
  {
    'label': 'İlaç hastaya uygulanamadı',
    'id': 34,
    'UserPermissionId': 34,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hastaya uygulanamadı. (Damar yolu tespit edilemedi)',
    'id': 35,
    'UserPermissionId': 35,
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hastaya uygulanamadı. (Hasta tedaviyi reddetti)',
    'id': 36,
    'UserPermissionId': 36,
    'background_color': '#47D4DC'
  }
];

export { data as options };
