var data = [{
  'application_end_date': '',
  'application_start_date': '',
  'application_time': 60,
  'ApplicationTypeId': 1,
  'calculated_dosage': 120,
  'CreatedBy': 1,
  'cycle': 1,
  'day': 1,
  'dosage': 80,
  'drop_minute': 33,
  'drug_day': '2022-01-01',
  'drug_note': 'Yavaş uygulanacak',
  'drug_order': 1,
  'DrugApplicationStatusId': 1,
  'DrugBrandsId': 1,
  'DrugId': 1,
  'DrugTypeId': 1,
  'FluidId': 1,
  'frequency_first': 1,
  'frequency_second': 1,
  'given_dosage': '',
  'id': 1,
  'infusion_rate': 100,
  'parent': 1,
  'PatientId': 1,
  'PatientTreatmentDrugsId': 1,
  'PatientTreatmentId': 1,
  'planned_dosage': 120,
  'related_drug_id': '',
  'UnitId': 1,
  'volume': 100,
}]

export { data as options };
