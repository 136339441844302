var data = [{
    'id': 1,
    'label': 'Bun',
}, {
    'id': 2,
    'label': 'Kreatinin',
}, {
    'id': 3,
    'label': 'Sodyum',
}, {
    'id': 4,
    'label': 'Potasyum',
}]

export { data as options };