var data = [{
  'id': 1,
  'DrugId': 23,
  'UserId': 145
}, {
  'id': 2,
  'DrugId': 24,
  'UserId': 145
}]

export { data as options };
