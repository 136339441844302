var data = [{
  'id': 1,
  'label': 'deksametazon'
}, {
  'id': 2,
  'label': 'paklitaksel'
}, {
  'id': 3,
  'label': 'dosetaksel'
}]

export { data as options };
