var data = [{
  'id': 1,
  'PatientId': 1,
  'PatientTreatmentId': 1,
  'DrugId': 1,
  'DrugTypeId': 1,
  'UnitId': 1,
  'ApplicationTypeId': 1,
  'FluidId': 1,
  'CreatedBy': 1,
  'drug_order': 1,
  'related_drug_id': '',
  'dosage': 80,
  'calculated_dosage': 120,
  'planned_dosage': 120,
  'frequency_first': 1,
  'frequency_second': 1,
  'drug_note': 'Yavaş uygulanacak',
  'volume': 100,
  'application_time': 60,
  'drop_minute': 33,
  'infusion_rate': 60,
  'days': '1,8',
  'period': 21,
  'cycle': 6,
  'delay': 0
}]

export { data as options };
