var data = [{
  'id': 1,
  'UserId': 1,
  'PermissionId': 1,
}, {
  'id': 2,
  'UserId': 1,
  'PermissionId': 2,
}, {
  'id': 3,
  'UserId': 1,
  'PermissionId': 3,
}]

export { data as options };
