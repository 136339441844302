<template>
  <div>
    <template v-for="(table_name, table_name_index) in d_sqlDatabaseList.table_order">
      <template v-if="p_tableShowDetails.type === 'all' || (p_tableShowDetails.type === 'selected' && p_tableShowDetails.table_name === table_name)">
        <h3> {{ table_name }} </h3>
        <h6> {{ d_sqlDatabaseList.tables[table_name].info }} </h6>
        <h6> Değişken Bilgileri </h6>
        <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
          <thead>
            <tr>
              <th>Değişken</th>
              <th>Tipi</th>
              <th>Açıklama</th>
              <th>İlişkili Tablo</th>
              <th>İlişkili Tablo Değişkeni</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(table_var, table_var_index) in d_sqlDatabaseList.tables[table_name].variable">
              <tr>
                <td> {{ table_var.name }} </td>
                <td> {{ table_var.type }} </td>
                <td> {{ table_var.info }} </td>
                <td> {{ table_var.relation_table }} </td>
                <td> {{ table_var.relation_table_variable }} </td>
              </tr>
            </template>
          </tbody>
        </table>
        <template v-if="d_sqlDatabaseList.tables[table_name].example_rows.length > 0">
        <h6>Tablo verileri</h6>
          <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
            <thead>
              <tr>
                <th v-for="(table_var, table_var_index) in d_sqlDatabaseList.tables[table_name].variable">
                  {{ table_var.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(example, example_index) in d_sqlDatabaseList.tables[table_name].example_rows">
                <tr>
                  <td v-for="(cell, cell_index) in example.row">
                    {{ cell }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <hr>
        <h6>Tablonun oluşturulması</h6>
        <div class="code-text"> {{ f_createCreateTableSql(table_name) }} </div>
        <!-- <b-textarea class="code-text" v-model="" rows="5"></b-textarea> -->
        <hr>
        <template v-for="(sql_example, sql_example_index) in d_sqlDatabaseList.tables[table_name].sql_list">
          <h6> {{ sql_example.name }} </h6>
          <div class="code-text"> {{ sql_example.sql }} </div>
          <hr>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SqlDatabase',
  components: {},
  props: {
    p_tableShowDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_sqlDatabaseList: require('@/options/sql_database').options
    }
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {
    this.f_prepareExampleRows();
  },
  methods: {
    f_prepareExampleRows: function () {
      let max_row_count = 10;
      for (let i in this.d_sqlDatabaseList.table_order) {
        let table_name = this.d_sqlDatabaseList.table_order[i];
        try {
          let table_data = require('@/tables/' + table_name).options;
          if (table_data.length > 0) {
            this.d_sqlDatabaseList.tables[table_name].example_rows = [];
            for (let table_data_index in table_data) {
              let new_row = { 'row': [] };
              for (let variable_index in this.d_sqlDatabaseList.tables[table_name].variable) {
                let var_name = this.d_sqlDatabaseList.tables[table_name].variable[variable_index].name;
                new_row.row.push(table_data[table_data_index][var_name]);
              }
              this.d_sqlDatabaseList.tables[table_name].example_rows.push(new_row);
              if (parseInt(table_data_index) === max_row_count) {
                break;
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    f_createCreateTableSql: function (table_name) {
      let table_data = this.d_sqlDatabaseList.tables[table_name];
      let create_table_sql = 'CREATE TABLE Woncology.' + table_name;
      if (table_data.variable) {
        create_table_sql += ' ( ';
        for (let variable_index in table_data.variable) {
          create_table_sql += table_data.variable[variable_index]['name'] + ' ' + table_data.variable[variable_index]['type'];
          if (parseInt(variable_index) !== table_data.variable.length - 1) {
            create_table_sql += ', ';
          }
        }
        create_table_sql += ' ); ';
      }
      return create_table_sql;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

