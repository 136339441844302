var data = [{
  'id': 1,
  'label': 'Onkoloji tedavilerinde ilaçların dozlarında hesaplamalarda vücut yüzey alanı değeri kullanılmaktadır.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 2,
  'label': 'Vücut yüzey alanı değerinin en güncel olduğu değerden ilaç dozlarının hesabı yapılması gerekmektedir.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 3,
  'label': 'Hastanın kilo değişimiyle birlikte ilaç dozları da değişmektedir.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 4,
  'label': 'Bu nedenle vücut yüzey alanı hesaplamalarının güncel olması çok önemlidir.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 5,
  'label': 'İlk belirlenen vücut yüzey alanı ile tedavi başlatılır.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 6,
  'label': '1 ay geçtikten sonra hastanın kilo kaybı nedeniyle vücut yüzey alanı değişebilir. Bu nedenle yeni gelen tedavileri için dozlar anlık olarak yeniden hesaplanır. Yeni hesaplanan doz değişimleri gösterilir.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}, {
  'id': 7,
  'label': 'Tedavisi vücut yüzey alanı gerektiren bir ilaç kullanılıyor ise vücut yüzey alanını yenilemesi için kullanıcının uyarılması önemlidir. Zorunlu olarak değiştirmesi gerekmemektedir. Bilgilendirme amaçlı uyarı yapılmaktadır.',
  'status': 0,
  'button_name': '',
  'trigger_point': '',
  'method_name': '',
  'info': '',
  'subject': 'bsa'
}]
export { data as options };
